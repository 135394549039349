import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82')];

export const server_loads = [2,9,14,15];

export const dictionary = {
	"/": [22],
	"/(pages)/(public)/ad_preview_raw": [~73,[2,17]],
	"/(pages)/(private)/analytics/home": [23,[2,3]],
	"/(pages)/(private)/candidates": [24,[2,3,4]],
	"/(pages)/(private)/candidates/applications": [25,[2,3,4]],
	"/(pages)/(private)/candidates/applications/[candidate_application_id]": [26,[2,3,4]],
	"/(pages)/(public)/candidate/interviews/[interview_id]": [~74,[2,17]],
	"/(pages)/(public)/candidate/jobs/[job_id]/application/[[candidate_application_id]]/finalize": [76,[2,17,19]],
	"/(pages)/(public)/candidate/jobs/[job_id]/application/[[candidate_application_id]]/submitted": [77,[2,17,19]],
	"/(pages)/(public)/candidate/jobs/[job_id]/application/[[candidate_application_id]]": [75,[2,17,19]],
	"/(pages)/(public)/candidate/jobs/[job_id]/interview": [~78,[2,17,19]],
	"/(pages)/(public)/candidate/jobs/[job_id]/posting": [79,[2,17,19]],
	"/(pages)/(public)/candidate/organizations/[org_id]/jobs": [80,[2,17,20]],
	"/(pages)/(public)/(auth)/forgot_password": [68,[2,17,18]],
	"/(pages)/(public)/get_started": [81,[2,17]],
	"/(pages)/(private)/home": [27,[2,3]],
	"/(pages)/(private)/jobs": [28,[2,3]],
	"/(pages)/(private)/jobs/create": [52,[2,3,10]],
	"/(pages)/(private)/jobs/intake": [53,[2,3]],
	"/(pages)/(private)/jobs/new": [54,[2,3]],
	"/(pages)/(private)/jobs/posted_successfully": [55,[2,3]],
	"/(pages)/(private)/jobs/[id]": [29,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/ad_setup": [~30,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/applicants": [31,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/application": [32,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/budgeting": [33,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/campaigns": [34,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/campaigns/new": [41,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/campaigns/[campaign_id]": [35,[2,3,6]],
	"/(pages)/(private)/jobs/[id]/campaigns/[campaign_id]/ads": [36,[2,3,6]],
	"/(pages)/(private)/jobs/[id]/campaigns/[campaign_id]/ads/new": [39,[2,3,6]],
	"/(pages)/(private)/jobs/[id]/campaigns/[campaign_id]/ads/[ad_id]": [37,[2,3,7]],
	"/(pages)/(private)/jobs/[id]/campaigns/[campaign_id]/ads/[ad_id]/delete": [38,[2,3,7]],
	"/(pages)/(private)/jobs/[id]/campaigns/[campaign_id]/delete": [40,[2,3,6]],
	"/(pages)/(private)/jobs/[id]/delete": [42,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/home": [43,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/integrations": [44,[2,3,5,8]],
	"/(pages)/(private)/jobs/[id]/integrations/linkedin": [45,[2,3,5,8]],
	"/(pages)/(private)/jobs/[id]/integrations/meta": [~46,[2,3,5,8]],
	"/(pages)/(private)/jobs/[id]/interview": [~47,[2,3,5,9]],
	"/(pages)/(private)/jobs/[id]/interview/interview_format": [48,[2,3,5,9]],
	"/(pages)/(private)/jobs/[id]/interview/recruiters": [49,[2,3,5,9]],
	"/(pages)/(private)/jobs/[id]/review": [~50,[2,3,5]],
	"/(pages)/(private)/jobs/[id]/settings": [51,[2,3,5]],
	"/(pages)/(public)/(auth)/login": [69,[2,17,18]],
	"/(pages)/(private)/organizations/[id]": [56,[2,3,12]],
	"/(pages)/(private)/organizations/[id]/members": [57,[2,3,12]],
	"/(pages)/(public)/(marketing)/privacy": [72,[2,17]],
	"/(pages)/(public)/(auth)/register_google": [71,[2,17,18]],
	"/(pages)/(public)/(auth)/register": [70,[2,17,18]],
	"/(pages)/shared/candidates/applications": [82,[2,21]],
	"/(pages)/(private)/tests/ad_image_square_v2": [58,[2,3,13]],
	"/(pages)/(private)/users/current/interviews": [~59,[2,3,14,15]],
	"/(pages)/(private)/users/current/interviews/availability": [60,[2,3,14,15]],
	"/(pages)/(private)/users/current/interviews/calendar_authorization_redirect": [~61,[2,3,14,15]],
	"/(pages)/(private)/users/current/interviews/calendars": [62,[2,3,14,15]],
	"/(pages)/(private)/users/current/interviews/video-conferencing-authorization-redirect": [~64,[2,3,14,15]],
	"/(pages)/(private)/users/current/interviews/video-conferencing": [63,[2,3,14,15]],
	"/(pages)/(private)/welcome/(index)": [65,[2,16]],
	"/(pages)/(private)/welcome/company_setup": [66,[2,16]],
	"/(pages)/(private)/welcome/job_setup": [67,[2,16]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';